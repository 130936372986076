import React from "react"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {Col, Container, Row} from 'reactstrap'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/kbusWrapper";


let TarifaBlock = ({tarifaImg, title, price1, price1Subtitle, price2, price2Subtitle, children}) => {
  const priceFormatted1= price1 !== null ?
    new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2
    }).format(price1) :
    null;

  const priceFormatted2= price2 !== null ?
    new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2
    }).format(price2) :
    null;

  return (
    <Row xs="1" className="my-5">
      <Col xs={{size: 12, offset: 0}} md={{size: 8, offset: 2}}>
        <Row>
          <Col xs={{size: 12, offset: 0}} md={{offset: 6}}>
            <h5 className="font-weight-bold pb-3">{title}</h5>
          </Col>
        </Row>
        <Row xs="1" md="2">
          <Col className="d-flex flex-column align-items-start align-items-md-end">
            <img src={tarifaImg.publicURL} alt={title}/>
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-start pt-3 pt-md-0">
            <div className="pb-3">
              <h1 className="font-weight-bold mb-0">{priceFormatted1}<span className="small ml-1">€</span></h1>
              <h6 className="text-muted pt-0">{price1Subtitle}</h6>
            </div>
            <div className={`${price2 ? 'd-block' : 'd-none'} pb-3`}>
              <h1 className="font-weight-bold mb-0">{priceFormatted2}<span className="small ml-1">€</span></h1>
              <h6 className="text-muted pt-0">{price2Subtitle}</h6>
            </div>
            <ReactMarkdown source={children} className="text-muted"/>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const TarifasPage = ({data}) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'tarifas', 'titulo')} />
        <Container className="pt-5">
          <PageTitle title={context.translate(data, 'tarifas', 'titulo')}/>
          {context.translate(data, 'tarifas', 'tarifas').map(item => (
            <div key={item.id}>
              <TarifaBlock
                tarifaImg={item.imagen}
                title={item.encabezado}
                children={item.descripcion}
                price1={item.precio}
                price1Subtitle={item.price1subtitulo}
                price2={item.precio2}
                price2Subtitle={item.price2subtitulo}
              />
              <PageTitle title=""/>
            </div>
            )
          )}
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default TarifasPage

export const pageQuery = graphql`  
  query {
    tarifas: strapiTarifas {
      titulo
      tarifas {
        id
        descripcion
        encabezado
        precio
        price1subtitulo
        precio2
        price2subtitulo
        imagen {
          publicURL
        }
      }
    }
    tarifas_eus: strapiTarifasEus {
      titulo
      tarifas {
        id
        descripcion
        encabezado
        precio
        price1subtitulo
        precio2
        price2subtitulo
        imagen {
          publicURL
        }
      }
    }
  }
`


